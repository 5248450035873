<template>
  <svg
    class="gf-linechart"
    :viewBox="viewBoxSizes"
  >
    <g :transform="`translate(${margin.left + 3}, ${10})`">
      <!-- Axes -->
      <g :class="xAxisClass" :transform="`translate(0, ${height})`"></g>
      <g :class="yAxisClass"></g>

      <!-- Line -->
      <g>
        <path :class="lineClass" d=""></path>
        <path :class="areaClass"></path>
      </g>

      <!-- Grids -->
      <g>
        <g :class="xGridClass" :transform="`translate(0, ${height})`"></g>
        <g :class="yGridClass"></g>
      </g>

      <!-- Tooltip -->
      <!-- <rect :width="width" :height="height" class="overlay"></rect>
      <g class="focus">
        <rect
          width="100"
          height="30"
          class="tooltip"
          y="0"
          rx="4"
          ry="4"
        ></rect>
      </g> -->
    </g>
  </svg>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'D3LineChart',
  data() {
    return {
      width: '100%',
      height: '100%',
      margin: {
        left: 20,
        right: 10,
        bottom: 10,
        top: 20,
      },
      scales: {
        x: null,
        y: null,
      },
      data: [],
      bisectDate: null,
    };
  },
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    chartId: String,
    options: {
      type: Object,
    },
    singleChart: Boolean,
  },
  computed: {
    lineName() {
      return `${this.chartId}-gf-line`;
    },
    lineClass() {
      return `${this.lineName} gf-line`;
    },
    areaName() {
      return `${this.chartId}-gf-area`;
    },
    areaClass() {
      return `${this.areaName} gf-area`;
    },
    xGridName() {
      return `${this.chartId}-gf-x-grid`;
    },
    xGridClass() {
      return `${this.xGridName} grid`;
    },
    yGridName() {
      return `${this.chartId}-gf-y-grid`;
    },
    yGridClass() {
      return `${this.yGridName} grid`;
    },
    xAxisName() {
      return `${this.chartId}-x-axis`;
    },
    xAxisClass() {
      return `${this.xAxisName}`;
    },
    yAxisName() {
      return `${this.chartId}-y-axis`;
    },
    yAxisClass() {
      return `${this.yAxisName}`;
    },
    chartWidth() {
      return '100%';
    },
    chartHeight() {
      // return ((this.width + this.margin.left + this.margin.right) / 16) * 9;
      return '100%';
    },
    widthSetter() {
      // console.log('this.singleChart', this.singleChart);
      if (this.singleChart) {
        if (window.innerWidth >= '1200') {
          // -30 for paddings
          console.log('singleChart > 1200');
          return 815;
        }
        if (window.innerWidth >= '768' && window.innerWidth < '992') {
          return 380;
        }
      }
      if (window.innerWidth >= '1200') {
        // -30 for paddings
        return 209.65;
      }
      if (window.innerWidth >= '768' && window.innerWidth < '992') {
        return 380;
      }
      return (window.innerWidth - 50);
    },
    heightSetter() {
      // if (window.innerWidth >= '1200') {
      //   // -30 for paddings
      //   return 104.8;
      // }
      // if (window.innerWidth >= '768' && window.innerWidth < '992') {
      //   return 213.75;
      // }
      if (this.singleChart) {
        return ((this.widthSetter / 16) * 6);
      }
      return ((this.widthSetter / 16) * 9);
    },
    viewBoxSizes() {
      // console.log('Math.min(this.width, this.height)', Math.min(this.width, this.height));
      // console.log('window.width', window.innerWidth);
      return `0 0 ${this.widthSetter + 30} ${this.heightSetter + 30}`;
    },
  },
  methods: {
    /**
     * setSize: This method will set the width, height
     * and also the margins of the chart.
     */
    setSize() {
      // console.log('Math.min(this.width, this.height) test', Math.min(this.width, this.height));
      // console.log('setsize window.width', window.innerWidth);
      // Width and Height
      // if (window.innerWidth >= '1200') {
      //   this.width = 209.65;// -30 for paddings
      //   this.height = 104.8;// -30 for paddings
      // } else if (window.innerWidth >= '992' && window.innerWidth < '1200') {
      //   this.width = 164.65;// -30 for paddings
      //   this.height = 105.49;// -30 for paddings
      // } else if (window.innerWidth >= '768' && window.innerWidth < '992') {
      //   this.width = 380;// -30 for paddings
      //   this.height = 213.75;// -30 for paddings
      // } else {
      //   this.width = this.options.width || 400 - this.margin.left - this.margin.right;
      //   this.height = this.options.height || 250 - this.margin.top - this.margin.top;
      // }
      this.width = this.widthSetter;
      this.height = this.heightSetter;
      // console.log('dim check', this.width, this.height);
    },
    setScales() {
      this.scales.x = d3
        .scaleTime()
        .domain(d3.extent(this.data, (d) => d.x))
        .range([0, this.width]);

      this.scales.y = d3
        .scaleLinear()
        .domain(d3.extent(this.data, (d) => d.y))
        .range([this.height, 0]);
    },
    renderAxes() {
      d3.select(`.${this.xAxisName}`)
        .call(d3.axisBottom(this.scales.x).ticks(7))
        .selectAll('.tick line')
        .attr('stroke', '#000')
        .attr('stroke-opacity', '0.1');

      d3.select(`.${this.yAxisName}`)
        .call(d3.axisLeft(this.scales.y).ticks(5).tickFormat((x) => x.toFixed()))
        .selectAll('.tick line')
        .attr('stroke', '#000')
        .attr('stroke-opacity', '0.1');

      // Change text color
      d3.selectAll(`.${this.yAxisName} text`).attr('color', '#999');
      d3.selectAll(`.${this.xAxisName} text`).attr('color', '#999');

      // Change path color
      d3.selectAll(`.${this.yAxisName} path`)
        .attr('stroke', '#000')
        .attr('stroke-opacity', '0');
      d3.selectAll(`.${this.xAxisName} path`)
        .attr('stroke', '#999')
        .attr('stroke-opacity', '0.1');
    },
    line() {
      const { x, y } = this.scales;
      return d3
        .line()
        .x((d) => x(d.x))
        .y((d) => y(d.y));
    },
    area() {
      const { x, y } = this.scales;
      return d3
        .area()
        .x((d) => x(d.x))
        .y0(this.height)
        .y1((d) => y(d.y));
    },
    renderLine() {
      d3.select(`.${this.lineName}`)
        .datum(this.data)
        .attr('d', this.line());
    },
    renderArea() {
      d3.select(`.${this.areaName}`)
        .datum(this.data)
        .attr('class', 'gf-area')
        .attr('d', this.area());
    },
    renderYGrid() {
      const yGrid = d3
        .axisLeft(this.scales.y)
        .ticks(5)
        .tickSize(-this.width)
        // .tickFormat('');
        .tickFormat((x) => x.toFixed());

      d3.select(`.${this.yGridName}`)
        .call(yGrid)
        .selectAll('line')
        .attr('stroke', '#000')
        .attr('stroke-opacity', '0.1')
        .attr('stroke-width', '1px');

      // Hide border paths
      d3.select(`.${this.yGridName} path`).attr('stroke-opacity', '0');
      d3.select(`.${this.xGridName} path`).attr('stroke-opacity', '0');
    },
    initToolTip() {
      const self = this;
      this.bisectDate = d3.bisector((d) => d.x).left;
      const focus = d3.select('.focus');
      d3.select('.overlay')
        .on('mouseover', () => focus.style('display', null))
        .on('mouseout', () => focus.style('display', 'null'))
        // eslint-disable-next-line func-names
        .on('mousemove', function () {
          const that = this;
          self.tooltipoOnMouseMove(that);
        });
    },
    // tooltipoOnMouseMove(that) {
    //   // console.log("TOOLTIP ON MOVE: ", that);
    //   const { x } = this.scales;
    //   const x0 = x.invert(d3.mouse(that)[0]);
    //   const i = this.bisectDate(this.data, x0, 1);
    //   const d0 = this.data[i - 1];
    //   const d1 = this.data[i];
    // },
    init() {
      this.setSize();
      this.setScales();
      this.renderYGrid();
      this.renderAxes();
      this.renderLine();
      // this.initToolTip();
      // this.renderArea();
    },
  },
  watch: {
    /**
     * Re-render the chart on data change
     */
    dataset(data) {
      this.data = data;
      this.init();
      console.log('data watch', this.data);
    },
  },
  mounted() {
    this.data = this.dataset;
    this.init();
    console.log('data mount', this.data);
  },
};
</script>

<style lang="scss" scoped>
// Line
.gf-line {
  stroke: #3cba54;
  stroke-width: 2px;
  fill: none;
}

// .grid {
//   fill: red;
// }

.grid line {
  stroke: blue;
  stroke-opacity: 1;
}

.grid path {
  stroke-width: 1;
}

.gf-area {
  fill: lightsteelblue;
}

.tooltip {
  fill: white;
  stroke: #444;
}

.overlay {
  fill: none;
  pointer-events: all;
}
</style>
